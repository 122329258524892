import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

export default () => (
  <>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header="Let's Connect" />
        <h2>
          Reach out to us directly at{' '}
          <a href='mailto:enquiry@buildingsmartsingapore.org'>
            enquiry@buildingsmartsingapore.org
          </a>
        </h2>
        <Divider />
      </Main>
    </Stack>
  </>
)
